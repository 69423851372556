import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import image from 'assets/img/icons/spot-illustrations/navbar-vertical.png';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { FiVideo } from 'react-icons/fi';
import CreateAppointment from '../../pages/landing/appointment/CreateAppointment';

const PurchaseCard = () => {
  const [show, setShow] = useState(true);
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);

  const [appointmentDate, setAppointmentDate] = useState();
  return (
    show && (
      <div className="settings my-3">
        <Card className="p-0 rounded-2 position-relative">
          <div
            className="position-absolute"
            style={{ right: '3px', top: '3px' }}
          >
            <FalconCloseButton
              size="sm"
              noOutline
              onClick={() => setShow(false)}
            />
          </div>
          <Card.Body className="text-center">
            <img src={image} alt="" width={80} />
            <p className="fs--2 mt-2">
              Erstklassige Servicequalität & eine individuell auf Sie
              zugeschnittene Lösung
            </p>
            <div className="d-grid gap-2">
              <Button
                type="button"
                onClick={() => {
                  setIsOpenAppointmentModal(!isOpenAppointmentModal);
                }}
                variant="outline-warning"
                size="lg"
                className="border-2 rounded-pill  py-2 fs-0"
              >
                Einen Termin vereinbaren{' '}
                <FiVideo className="text-warning fs-0" />
              </Button>
              <CreateAppointment
                isOpenAppointmentModal={isOpenAppointmentModal}
                setIsOpenAppointmentModal={setIsOpenAppointmentModal}
                appointmentDate={appointmentDate}
                setAppointmentDate={setAppointmentDate}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
    )
  );
};

export default PurchaseCard;
