import React from 'react';
import blogList from './blogList';
import Section from 'components/common/Section';
import Process from './Process';

import { isIterableArray } from 'helpers/utils';
import SectionHeader from '../landing/SectionHeader';

const Processes = () => (
  <Section>
    <SectionHeader
      title="Benötigen Sie Hilfe, um Ihre unternehmerischen Ziele zu realisieren? "
      subtitle="Wir stehen Ihnen gerne zur Seite, um Sie bei der Erreichung Ihrer geschäftlichen Ziele zu unterstützen."
    />
    {isIterableArray(blogList) &&
      blogList.map((process, index) => (
        <Process key={process.color} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Processes;
