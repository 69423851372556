import { version } from 'config';
import packageInfo from '../../package.json';

const { homepage } = packageInfo;

export const faqs = [
  {
    id: 0,
    title: `How does Falcon's pricing work?`,
    description: `The free version of Falcon is available for teams of up to 15 people. Our Falcon Premium plans of 15 or fewer qualify for a small team discount. As your team grows to 20 users or more and gets more value out of Falcon, you'll get closer to our standard monthly price per seat. The price of a paid Falcon plan is tiered, starting in groups of 5 and 10 users, based on the number of people you have in your Team or Organization.`
  },
  {
    id: 1,
    title: 'What forms of payment do you accept?',
    description: `You can purchase Falcon with any major credit card. For annual subscriptions, we can issue an invoice payable by bank transfer or check. Please contact us to arrange an invoice purchase. Monthly purchases must be paid for by credit card.`
  },
  {
    id: 2,
    title: 'We need to add more people to our team. How will that be billed?',
    description: `You can add as many new teammates as you need before changing your subscription. We will subsequently ask you to correct your subscription to cover current usage.`
  },
  {
    id: 3,
    title: `How secure is Falcon?`,
    description: `Protecting the data you trust to Falcon is our first priority. Falcon uses physical, procedural, and technical safeguards to preserve the integrity and security of your information. We regularly back up your data to prevent data loss and aid in recovery. Additionally, we host data in secure SSAE 16 / SOC1 certified data centers, implement firewalls and access restrictions on our servers to better protect your information, and work with third party security researchers to ensure our practices are secure.`
  },
  {
    id: 4,
    title: `Will I be charged sales tax?`,
    description: `As of May 2016, state and local sales tax will be applied to fees charged to customers with a billing address in the State of New York.`
  },
  {
    id: 5,
    title: `Do you offer discounts?`,
    description: `We've built in discounts at each tier for teams smaller than 15 members. We also offer two months for free in exchange for an annual subscription.`
  },
  {
    id: 6,
    title: `Do you offer academic pricing?`,
    description: `We're happy to work with student groups using Falcon. Contact Us`
  },
  {
    id: 7,
    title: `Is there an on-premise version of Falcon?`,
    description: `We are passionate about the web. We don't plan to offer an internally hosted version of Falcon. We hope you trust us to provide a robust and secure service so you can do the work only your team can do.`
  },
  {
    id: 8,
    title: `What is your refund policy?`,
    description: `We do not offer refunds apart from exceptions listed below. If you cancel your plan before the next renewal cycle, you will retain access to paid features until the end of your subscription period. When your subscription expires, you will lose access to paid features and all data associated with those features. Exceptions to our refund policy: canceling within 48 hours of initial charge will result in a full refund. If you cancel within this timeframe, you will lose access to paid features and associated data immediately upon canceling.`
  }
];

export const faqAccordions = [
  {
    id: 0,
    title: 'Wettbewerbsfähigkeit und die Innovationskraft zu steigern?',
    description:
      '<p class = "mb-0">Free Electron kann seinen Kunden dabei helfen, ihre Wettbewerbsfähigkeit und Innovationskraft zu steigern, indem es seinen Kunden das Wissen und die Technologien bietet, die sie benötigen, um ihre Ideen zu verwirklichen. Dies beinhaltet die Bereitstellung von Software-Entwicklungs-Tools, die von erfahrenen Experten entwickelt wurden, Beratung bei der Software-Entwicklung, E-Commerce-Integration und vieles mehr. Free Electron kann auch den Kunden helfen, neue Ideen zu entwickeln und diese zu nutzen, um ihren Wettbewerb vorzusehen. Schließlich bietet Free Electron seinen Kunden auch ein umfassendes Portfolio an Cloud-Services, die es ihnen ermöglichen, Kosten zu senken, Einsparungen zu erzielen und innovative Lösungen zu entwickeln.</p>'
  },
  {
    id: 1,
    title: 'unsere Produkt- und Servicequalität zu verbessern?',
    description:
      '<p class = "mb-0">Free-Electron bietet ein breites Spektrum an Dienstleistungen, die dazu beitragen können, die Produkt- und Servicequalität Ihres Unternehmens zu verbessern. Unsere Experten bieten u. a. Beratung sowie Prozessoptimierung und Qualitätsmanagement. Zudem können Sie mit Free-Electron Ihre Anwendungen und Tools optimieren, Dokumentationen erstellen, Schulungen und Testanwendungen entwickeln. Wir bieten auch Konsultationen zur Definition und Umsetzung von Qualitätsstandards. Darüber hinaus stehen uns auch zahlreiche branchenspezifische Qualitätsmanagement-Frameworks zur Verfügung, um Ihnen bei der Verbesserung Ihrer Produkt- und Servicequalität zu helfen.</p>'
  },
  {
    id: 2,
    title: 'Konflikte in unserem Unternehmen effektiv zu lösen?',
    description:
      '<p class = "mb-0">Free Electron bietet eine Reihe von praktischen Tools und Ressourcen, die dabei helfen können, Konflikte in einem Unternehmen effektiv zu lösen. Zum Beispiel könnten Sie ein Templates verwenden, um den Konflikt zu identifizieren und zu priorisieren. Zudem bietet die Website ein Forum, in dem verschiedene Ansätze zur Konfliktlösung diskutiert werden können. Außerdem können Sie unseren Expertenrat in Anspruch nehmen, um mehr Informationen zu erhalten und Ideen zu sammeln.</p>'
  },
  {
    id: 3,
    title: 'um sicherzustellen, dass wir nachhaltiges Wachstum erzielen?',
    description:
      '<p class = "mb-0">Free Electron bietet eine Reihe von Tools und Ressourcen an, um Unternehmen dabei zu helfen, nachhaltiges Wachstum zu erzielen. Dazu gehören: </p>' +
      ' <ul type="1">\n' +
      '          <li className="p-3 text-justify">\n' +
      '           Beratungsleistungen rund um das Thema Nachhaltigkeit, um Unternehmen bei der Umsetzung nachhaltiger Strategien zu unterstützen\n' +
      '          </li>\n' +
      '          <li className="p-3 text-justify">\n' +
      '            Tools zur Erhebung von Nachhaltigkeitsdaten, um Erkenntnisse und Einblicke in Umwelt, Soziales und Governance (ESG) zu liefern\n' +
      '          </li>\n' +
      '          <li className="p-3 text-justify">\n' +
      '            Ein Netzwerk an Experten aus unterschiedlichen Branchen und verschiedenen geografischen Regionen, das Unternehmen beim Aufbau einer ganzheitlichen Nachhaltigkeitsstrategie unterstützt\n' +
      '          </li>\n' +
      '          <li className="p-3 text-justify">\n' +
      '          Fortgeschrittene analytics-Tools und Services, die Unternehmen helfen, Möglichkeiten zu erkennen, ihre nachhaltigen Initiativen effizienter zu gestalten und zu verwalten\n' +
      '          </li>\n' +
      '          <li className="p-3 text-justify">\n' +
      '          Kurse zur Nachhaltigkeit, die Unternehmen befähigen, nachhaltige Geschäftspraktiken zu implementieren.\n' +
      '          </li>\n' +
      '        </ul>' +
      '<p class = "mb-0">Damit kann Free-Electron.de Unternehmen dabei helfen, nachhaltiges Wachstum zu erzielen, indem sie Expertenwissen, analytische Einblicke und kontinuierliche Unterstützung bereitstellt.</p>'
  },
  {
    id: 4,
    title: 'Kundenzufriedenheit zu steigern?',
    description:
      '<p class = "mb-0">Free Electron kann helfen, die Kundenzufriedenheit zu steigern, indem es eine Reihe von Dienstleistungen und Tools bereitstellt, die darauf abzielen, die Kundenerfahrung zu verbessern. Mit diesen können Unternehmen Kundenfeedback sammeln und analysieren, Kundenkommunikation und Echtzeitanalyse für eine schnellere Reaktion auf Änderungen durchführen, und die automatisierte Kundenservicelösung von Free Electron einsetzen, um Kunden zufrieden zu stellen. Unternehmen können auch die vollständige Kundenkontakthistorie verfolgen und die Kundenzufriedenheit überwachen und maßgeschneiderte Kundenerfahrungen bereitstellen, um die Kundenerwartungen zu übertreffen.</p>'
  },
  {
    id: 5,
    title: 'positive Unternehmenskultur zu entwickeln?',
    description:
      '<p class = "mb-0">Free Electron kann helfen, eine positive Unternehmenskultur zu entwickeln, indem es Unternehmen befähigt, ein modernes technologiebasiertes Arbeitsumfeld zu schaffen, das die Motivation, Kommunikation, Leistung und Zufriedenheit der Mitarbeiter fördert. Unser interaktives Tool ermöglicht die Entwicklung eines benutzerorientierten Arbeitserlebnisses, indem es IT-Verbindungen logisch integriert und damit eine bessere Kommunikation ermöglicht. Wir stellen auch schlanke, leistungsstarke und benutzerfreundliche Tools zur Verfügung, die Unternehmen befähigen, wichtige Arbeitsprozesse effizienter zu gestalten. Wir unterstützen die dynamische Arbeitsteilung durch die Übertragung leistungsstarker Tools und die Erstellung kollaborativer Arbeitsumgebungen. Diese Technologien können Unternehmen helfen, neue Wege zu finden, um die Interaktion und Kommunikation zwischen den Mitarbeitern zu stärken.</p>'
  },
  {
    id: 6,
    title: 'Profitabilität unserer Firma zu erhöhen?',
    description:
      '<p class = "mb-0">Free Electron bietet Unternehmen eine Reihe von technischen Beratungsdiensten an, die Ihnen helfen können, die Profitabilität zu erhöhen. Diese Dienste reichen von Konzeptentwicklung bis hin zur Umsetzung innovativer Technologien, die Ihnen helfen, effizienter zu werden und gleichzeitig Kosten zu senken. Wir können eine professionelle Beratung anbieten, damit Sie die Anforderungen Ihres Unternehmens besser verstehen und zielgerichteter anwenden können. Darüber hinaus analysieren wir Ihre einzigartigen Geschäftsbedingungen und erarbeiten ein spezielles Konzept, das auf Ihre Bedürfnisse zugeschnitten ist und Ihnen bei der Verbesserung der Profitingesundheit Ihres Unternehmens hilft.</p>'
  },
  {
    id: 7,
    title: 'Vorteile gegenüber der Konkurrenz zu schaffen?',
    description:
      '<p class = "mb-0">Free Electron kann Ihnen helfen, Vorteile gegenüber der Konkurrenz zu schaffen, indem es Ihnen ermöglicht, innovative Technologien zu entwickeln und zu nutzen, die einen Wettbewerbsvorteil schaffen. Darüber hinaus bietet Free Electron Unternehmen und Entwicklern eine umfassende Plattform, auf der sie neue Ideen für ihre Produkte und Dienstleistungen diskutieren und entwickeln können. Schließlich liefern wir Entwicklern durch Trainings und Schulungen die nötigen Fähigkeiten, um innovative Lösungen für die Problemstellungen ihrer Kunden zu schaffen. Diese Kombination aus Technologie, Know-how und Zugang zu neuesten Informationen ermöglicht es Unternehmen, sich einen strategischen Vorteil gegenüber ihren Wettbewerbern zu verschaffen.</p>'
  },
  {
    id: 8,
    title: 'nachhaltig zu wachsen?',
    description:
      '<p class = "mb-0">Free Electron bietet eine Reihe von Dienstleistungen an, um Unternehmen beim nachhaltigen Wachstum zu unterstützen. Dazu gehören unter anderem die Unterstützung bei der Identifizierung von langfristigen Wachstumschancen, der Entwicklung von nachhaltigen Unternehmensstrategien sowie der Umsetzung von Maßnahmen zur Steigerung des Unternehmenserfolgs. Zusätzlich bieten sie auch Beratung zur Verringerung von Ressourcenverbrauch und Abfallproduktion, zur Verbesserung der Energieeffizienz, zur Reduzierung von Auswirkungen auf die Umwelt sowie zur Implementierung eines ökologischen Managementsystems.</p>'
  },
  {
    id: 9,
    title: 'Markenbewusstsein zu schaffen?',
    description:
      '<p class = "mb-0">Free Electron kann Ihnen helfen, Markenbewusstsein zu schaffen, indem es konsistente, professionelle und hochwertige Inhalte für Ihre Social-Media-Kanäle erstellt. Es kann SEO (Search Engine Optimization) verwenden, um Ihre Markenpräsenz in den Suchmaschinen zu steigern, und es kann durch Werbung und Social Media Marketing Ihrer Marke mehr Aufmerksamkeit schenken. Dazu können auch lokale PR-Events, E-Mail-Marketingkampagnen und Influencer-Kooperationen helfen.</p>'
  },
  {
    id: 10,
    title: 'unsere Firma kontinuierlich zu verbessern?',
    description:
      '<p class = "mb-0">Free Electron kann Unternehmen helfen, auf dem neuesten Stand der Technik zu bleiben, indem wir ihnen Beratung rund um die Digitalisierungs- und Automatisierungstechnologien sowie die Optimierung ihrer Prozesse und Systeme anbieten. Darüber hinaus können wir Unternehmen auch Change-Management-Prozesse begleiten, um sicherzustellen, dass die in der Digitalisierungs- und Automatisierungstechnologien erzielten Ergebnisse auch den Anforderungen des Unternehmens entsprechen. Wir können Unternehmen auch dabei helfen, ihre IT-Systeme zu überwachen und zu optimieren, um die Produktivität und Effizienz zu maximieren. Außerdem können wir Unternehmen dabei unterstützen, ihre Daten effizient zu verwalten und zu beschützen.</p>'
  }
];

export const faqDoc = [
  {
    id: 1,
    title: 'How to start working with Falcon React',
    description: `<p class='mb-0'>Install latest version of Node js to your PC <a href='https://nodejs.dev/' target="_blank">https://nodejs.dev/</a> Download the template file and unzip it.
    You will get 2 files in the folder. Now unzip the <code>falcon-react-${version}</code> directory and open this directory from your terminal.
    Run <code>npm install </code> from your terminal.
    After installing all the required packages, run <code>npm start</code>. <br>
    Please check the following link to see How to Getting started in Falcon React: <a target='_blank' href='//${homepage}/documentation/getting-started'>${homepage}/documentation/getting-started<a/>.</p>`
  },
  {
    id: 2,
    title:
      'Is there a specific version of Node.js I should use for Falcon React?',
    description: `<p class="mb-0">Node version to <code>16.15.1</code> or any further updated version.</p>`
  },

  {
    id: 3,
    title: 'What React library is used for general API integration?',
    description: `<p class="mb-0">We used react context API for general API integration.</p>`
  },
  {
    id: 4,
    title: 'How are the SEO headers built?',
    description: `<p class="mb-0">We didn't build with any SEO headers by default. You have to integrate it manually by yourself.</p>`
  },
  {
    id: 5,
    title:
      'Which bootstrap react framework you are using? Reactstrap or React-Bootstrap?',
    description: `<p class="mb-0">We used the <b>reactstrap</b> for Falcon React <code>v2.x</code> and <b>React bootstrap</b> for Falcon React <code>v3.x</code>.</p>`
  },
  {
    id: 6,
    title: 'Where will I get the Figma Files?',
    description: `<p >You will find the Figma link in the <code>README.md</code> file on your downloaded copy of Falcon. Here is the detailed documentation: <a target='_blank' href='//${homepage}/documentation/design-file'> ${homepage}/documentation/design-file</a>.</p>
    <p class='mb-0'>If you still have any problem with the link, reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a></p>`
  },
  {
    id: 7,
    title:
      'What are the bare minimum files just to get the layout and navigation?',
    description: `<p >You can start from the Falcon’s Starter page where the minimum required components are included to get layout and navigation.
    More specifically, the below list of files are required to work with layout and navigation:<br>
    <code>src/assets</code><br>
    <code>src/components/common</code><br>
    <code>src/components/footer</code><br>
    <code>src/components/navbar</code><br>
    <code>src/components/notification</code><br>
    <code>src/components/pages/Starter.js</code><br>
    <code>src/components/settings-panel</code><br>
    <code>src/context</code><br>
    <code>src/data</code><br>
    <code>src/helpers</code><br>
    <code>src/hooks</code><br>
    <code>src/layouts/Layout.js</code><br>
    <code>src/layouts/MainLayout.js</code><br>
    <code>src/layouts/MainRoutes.js</code><br>
    <code>src/reducers</code><br>
    <code>src/routes</code><br>
    <code>src/App.js</code><br>
    <code>src/Config.js</code><br>
    <code>src/Index.js</code><br>
    <code>src/Main.js</code></p>

    <p>Note:  Remember deleting other files/directories will be the cause of errors. You have to remove the corresponding import statement and unnecessary lines from your code manually.
    </p>
    <p class="mb-0">We have created a mini version of Falcon React with all the essential files only. If you are interested, please reach us out with your purchase ID at <a href="mailto:support@themewagon.com">support@themewagon.com</a>.
    </p>`
  },
  {
    id: 8,
    title: 'How to compile SCSS files with Falcon React?',
    description: `<p class="mb-0">To compile scss files in Falcon react run npm run <code>build:css</code> from your terminal, or you can watch the scss files by running npm run <code>watch:css</code> to a separate terminal. 
    Check the link below to have a look on the styling documentation in Falcon React <a target='_blank' href='//${homepage}/documentation/styling'> ${homepage}/documentation/styling</a>
    </p>`
  },
  {
    id: 9,
    title: 'How to update from Falcon React v2.x to the upper version?',
    description: `<p><b>Updating from any earlier version of 3.x to ${version}:</b></br>
    Check out the following link and follow the changes: <a target='_blank' href='//${homepage}/changelog'> ${homepage}/changelog</a>
    </p>
    <p class='mb-0'><b>Updating from version 2 to version 3:</b></br>
    As the latest versions of templates and all their packages have different structures behind their components, you have to update all the components manually into your project.<br>
    So, it would be better to start building applications with the Bootstrap 5 version of Falcon from scratch. You can include any components from your existing project to your current project. Bring the component and its required SCSS and place it into your current project.
    </p>`
  }
];
