import React from 'react';
import './TextSVG.css';
import { ReactComponent as TextSVG } from '../../../../assets/img/free-electron/logo.svg';
import { Card, Col, Row } from 'react-bootstrap';

export default function Loading() {
  return (
    <Card>
      <Card.Body className="overflow-hidden p-lg-6">
        <Row className="align-items-center justify-content-between">
          <Col lg={6}>
            <div className="TextSVG">
              <TextSVG />
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
