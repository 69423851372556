export default [
  {
    description:
      '"Glaube an deine Ideen und tu, was nötig ist, um sie zu verwirklichen," ',
    author: 'Steve Jobs'
  },
  {
    description:
      '"Die Digitalisierung ermöglicht es uns, größere und bessere Produkte zu schaffen," ',
    author: 'Bill Gates'
  },
  {
    description: '"Die Veränderung ist die einzige Konstante," ',
    author: 'Heraklit'
  },
  {
    description: '"Management ist die Kunst, Dinge richtig zu machen," ',
    author: 'Peter Drucker '
  },
  {
    description: '"Es gibt keine Probleme, nur Lösungen,"  ',
    author: 'Thomas Edison '
  },
  {
    description:
      '"Wer immer tut, was er schon kann, bleibt immer das, was er schon ist,"  ',
    author: 'Henry Ford '
  },
  {
    description: '"Werden Sie heute besser als Sie gestern waren,"  ',
    author: 'Mahatma Gandhi '
  },
  {
    description:
      '„Die Digitalisierung ermöglicht eine neue Art der Kollaboration und Ideenfindung.”  ',
    author: 'Jeff Bezos '
  },
  {
    description:
      '„Du musst bereit sein, Dinge zu ändern, um Erfolg zu haben.” ',
    author: 'David Ogilvy '
  },
  {
    description:
      '„Gewöhnliche Menschen denken nur an die Chancen, Erfolgreiche Menschen denken an die Lösungen.”',
    author: 'Denis Waitley '
  },
  {
    description:
      '„In der digitalen Welt müssen wir lernen, schnell zu reagieren.”- ',
    author: 'Bill Gates '
  },
  {
    description:
      '„Es ist nur durch die Herausforderungen des Wandels, dass eine Organisation wächst.”  ',
    author: 'John Kotter '
  },
  {
    description: '„Gute Ideen sind wertlos, wenn man sie nicht umsetzt.”  ',
    author: 'Thomas Edison '
  },
  {
    description:
      '„Durch innovative Ideen und eine starke Führung können Veränderungen erfolgreich umgesetzt werden.”  ',
    author: 'Jack Welch '
  }
];
