export const strings = {
  en: {
    'Tomorrow will be too late!': 'Tomorrow will be too late!',
    leadText:
      'Benefit from our exclusively designed solutions to digitally transform your business!',

    Efficiency: 'Efficiency',
    Productivity: 'Productivity',
    Saving: 'Saving',
    perfection: 'perfection',
    Innovation: 'Innovation',
    motivation: 'motivation',
    Satisfaction: 'Satisfaction',
    Security: 'Security',

    'to your company': 'to your company'
  },
  de: {
    'Tomorrow will be too late!': 'Morgen ist es zu spät!',
    leadText:
      'Profitieren Sie von unseren exklusiv konzipierten Lösungen, um Ihr Unternehmen digital zu transformieren!',

    Efficiency: 'Effizienz ',
    Productivity: 'Produktivität',
    Saving: 'Einsparung ',
    perfection: 'Perfektion',
    Innovation: 'Innovation',
    motivation: 'Motivation',
    Satisfaction: 'Zufriedenheit',
    Security: 'Sicherheit',
    'to your company': 'zu Ihrem Unternehmen'
  }
};
