export default [
  {
    title: 'Langfristige Unternehmensziele',
    date: '10.03.2023',
    read: '7 min',
    to: '/blog'
  },
  {
    title: 'Pareto principle',
    date: 'coming soon',
    read: '3min',
    star: true
  },
  {
    title: 'Tomorrow will be too late!',
    date: 'coming soon',
    read: '2min'
  },
  {
    title: 'Digital companies',
    date: 'coming soon',
    read: '10min'
  }
];
