import React from 'react';
import processList from 'data/feature/processList';
import Section from 'components/common/Section';
import Process from './Process';
import SectionHeader from '../SectionHeader';
import { isIterableArray } from 'helpers/utils';
import { I18n } from '@aws-amplify/core';

const Processes = () => (
  <Section>
    <SectionHeader
      title={I18n.get('Vorgehensweise bei der Auftragsentwicklung')}
      subtitle="Besonderen Schritte, die in der Free Electron befolgt werden, um ein neues Softwareprodukt  zu erstellen"
    />
    {isIterableArray(processList) &&
      processList.map((process, index) => (
        <Process key={process.color} isFirst={index === 0} {...process} />
      ))}
  </Section>
);

export default Processes;
