/* eslint-disable react/react-in-jsx-scope */
import img1 from 'assets/img/support-desk/reports/1.png';
import img2 from 'assets/img/support-desk/reports/2.png';
import img3 from 'assets/img/support-desk/reports/3.png';
import img4 from 'assets/img/support-desk/reports/4.png';
import img5 from 'assets/img/support-desk/reports/5.png';
import img6 from 'assets/img/support-desk/reports/6.png';
import img7 from 'assets/img/support-desk/reports/7.png';
import {
  FiAtSign,
  FiBookOpen,
  FiBriefcase,
  FiClipboard,
  FiClock,
  FiCloud,
  FiGitPullRequest,
  FiRepeat,
  FiShoppingCart,
  FiTool,
  FiTrendingUp,
  FiUser,
  FiUsers
} from 'react-icons/fi';

export const reports = {
  analysis: [
    {
      title: 'In-Depth Helpdesk',
      description: 'an overview of your helpdesk system',
      img: img1
    },
    {
      title: 'Trends in Ticket Volume',
      description: 'an overview of the number of tickets',
      img: img2
    }
  ],
  customerSatisfaction: [
    {
      title: 'Analysis of the Top Customers',
      description: 'Check out our customer stories',
      img: img3
    },
    {
      title: 'Customer Satisfaction Survey',
      description: 'Check out the report details',
      img: img4
    }
  ],
  productivity: [
    {
      title: 'Performance of Agents',
      description: 'Check out the report details',
      img: img5
    },
    {
      title: 'Performance in a Group',
      description: 'Check out the report details',
      img: img6
    },
    {
      title: 'Distribution of Performance',
      description: 'Check out the report details',
      img: img7
    }
  ]
};

export const team = [
  {
    icon: <FiUser className="text-primary fs-2" />,
    color: 'primary',
    title: 'Agents',
    description:
      "Define agents' scope of work, type, language, and other details."
  },
  {
    icon: <FiUsers className="text-info fs-2" />,
    color: 'info',
    title: 'Groups',
    description:
      'Agents can be organized and unattended tickets can be notified.'
  },
  {
    icon: <FiGitPullRequest className="text-warning fs-2" />,
    color: 'warning',
    title: 'Roles',
    description: 'Provide agents with fine-grained access and privileges.'
  },
  {
    icon: <FiClock className="text-success fs-2" />,
    color: 'success',
    title: 'Working Hours',
    description:
      'To set client expectations, define operating hours and holidays.'
  },
  {
    icon: <FiBriefcase className="text-primary fs-2" />,
    color: 'primary',
    title: 'Skills',
    description: 'Assign kinds of tickets to agents based on their expertise.'
  },
  {
    icon: <FiRepeat className="text-info fs-2" />,
    color: 'info',
    title: 'Agent Changes',
    description: 'Create and manage agent schedules all in one spot.'
  }
];

export const account = [
  {
    icon: <FiClipboard className="text-primary fs-2" />,
    color: 'primary',
    title: 'Management',
    description:
      'View the status of your account as well as your invoice email address.',
    href: '/dashboard/project-management'
  },
  {
    icon: <FiShoppingCart className="text-info fs-2" />,
    color: 'info',
    title: 'E-Commerce',
    description:
      'Handeln Sie elektronisch,Wir bringen Ihren gesamten Handelsverkehr im Internet, unter anderem den Kauf und Verkauf von technische Waren im Internet.',
    href: '/dashboard/e-commerce'
  },
  {
    icon: <FiBookOpen className="text-warning fs-2" />,
    color: 'warning',
    title: 'LMS-Lernplattform',
    description:
      'Bereitstellung von Lernmaterialien und der Organisation von Lernvorgängen in Ihre Firma',
    href: '/dashboard/lms'
  },
  {
    icon: <FiCloud className="text-success fs-2" />,
    color: 'primary',
    title: 'SaaS Software-as-a-Service',
    description:
      'Nutzer greifen bei Ihrer Software as a Service auf die gemieteten Software-Anwendungen über ein Netzwerk zu und teilen sich dabei IT-Ressourcen wie Hardware und Plattform',
    href: '/dashboard/saas'
  },
  {
    icon: <FiTool className="text-primary fs-2" />,
    color: 'primary',
    title: 'Servicedesk',
    description:
      'Servicedesk kümmert sich demnach um Vorfälle und Serviceanfragen, aber auch um die Kommunikation mit den Benutzern',
    href: '/dashboard/support-desk'
  },
  {
    icon: <FiTrendingUp className="text-info fs-2" />,
    color: 'info',
    title: 'Analytics',
    description:
      'Mit Analytics lassen sich Ziele definieren und Erfolge von Werbekampagnen messen',
    href: '/dashboard/analytics'
  },
  {
    icon: <FiUser className="text-primary fs-2" />,
    color: 'primary',
    title: 'Agents',
    description:
      "Define agents' scope of work, type, language, and other details.",
    href: '/dashboard/webapp'
  },
  {
    icon: <FiUsers className="text-info fs-2" />,
    color: 'info',
    title: 'CRM-Kundenbeziehungsmanagement',
    description:
      'Gestalten Sie der Beziehungen und Interaktionen Ihrer Organisation mit bestehenden und potenziellen Kunden systematisch!\n',
    href: '/dashboard/crm'
  },
  {
    icon: <FiAtSign className="text-warning fs-2" />,
    color: 'warning',
    title: ' Internetpräsenz',
    description:
      ' Internetauftritte, Kontaktmöglichkeiten wie E-Mail, Dateiangebote, oder sogar Spiele !',
    href: '/'
  }
];
