export default [
  {
    media: { icon: ['fab', 'sass'], color: 'warning', className: 'fs-4' },
    title: 'Responsive',
    description:
      'Unsere Lösungen passen sich an unterschiedliche Bildschirmgrößen und Geräte (Handy, Desktop, Laptop, Tablet) an.'
  },
  {
    media: {
      icon: ['fab', 'sass'],
      color: 'warning',
      className: 'fs-5'
    },
    title: 'Multi-Sprachigkeit',
    description:
      'Nicht nur wir selbst, sondern auch all unsere Lösungen sind mehrsprachig.'
  },
  {
    media: {
      icon: ['fab', 'facebook-f'],
      color: 'warning',
      className: 'fs-4'
    },
    title: 'Moderner  SPW',
    description:
      'Reaktionsfähige und hochinteraktive Web-Applikationen mit Single-Page-Anwendungen (SPA).'
  },
  {
    media: { icon: ['fab', 'sass'], color: 'warning', className: 'fs-4' },
    title: 'Prozesskenntnisse',
    description:
      'Wir kennen die erforderlichen Maßnahmen, um ein Unternehmen erfolgreich zu betreiben.'
  },
  {
    media: { icon: ['fab', 'node-js'], color: 'warning', className: 'fs-5' },
    title: ' Zertifizierter AWS Solution Architect',
    description:
      'Offiziell bestätigtes Wissen im Umgang mit der Amazon Web Services Cloud-Technologie.'
  },
  {
    media: { icon: ['fab', 'gulp'], color: 'warning', className: 'fs-6' },
    title: 'Branchenkenntnisse',
    description:
      'Fundierte Kenntnisse im Bereich Metallverarbeitung, Metallbau, Maschinenbau, Hydrauliktechnik, Schweißtechnik und Anlagenbau.'
  }
];
