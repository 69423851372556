import illustration1 from 'assets/img/free-electron/Pair programming-rafiki.svg';
import illustration2 from 'assets/img/free-electron/Digital transformation-rafiki.svg';
import illustration3 from 'assets/img/free-electron/In no time-bro.svg';
import illustration4 from 'assets/img/free-electron/Call center-bro.svg';
import illustration5 from 'assets/img/free-electron/Service 24_7-bro.svg';
import illustration6 from 'assets/img/free-electron/Mobile testing-pana.svg';
import illustration7 from 'assets/img/free-electron/Software code testing-amico.svg';
export default [
  {
    icon: ['far', 'lightbulb'],
    iconText: 'STRATEGIE',
    color: 'warning',
    title: 'Kostenlose Strategieberatung',
    description:
      'Wir werden uns über Ihre aktuelle Situation austauschen und besprechen, welche Ziele Sie erreichen möchten.Wir werden gemeinsam mögliche Strategien erarbeiten, die Ihnen helfen, Ihre Ziele zu erreichen. Am Ende der Beratung erhalten Sie ein konkretes Handlungsprogramm, damit Sie Ihre Ziele erfolgreich erreichen können.',
    image: illustration4
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'ANALYSE',
    color: 'warning',
    title: 'Anforderungsanalyse',
    description:
      'In diesem Schritt werden die Anforderungen des Auftraggebers analysiert. Dies schließt die Analyse der Funktionalität, der Benutzeroberfläche, des Designs und des Geschäftsprozesses ein.',
    image: illustration2,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'DESIGN',
    color: 'warning',
    title: 'Planung und Design',
    description:
      ' Hier werden der Entwicklungsprozess, der Arbeitsablauf und die technischen Anforderungen definiert, um die Anforderungen des Auftraggebers zu erfüllen.',
    image: illustration6
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'ENTWICKLUNG',
    color: 'warning',
    title: 'Entwicklung und Implementierung',
    description:
      'In diesem Schritt wird die Software entwickelt und implementiert.',
    image: illustration1,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'PRÜFEN',
    color: 'warning',
    title: 'Testen',
    description:
      'Alle Tests müssen durchgeführt werden, um sicherzustellen, dass die Software den Anforderungen des Auftraggebers entspricht.',
    image: illustration7
  },
  {
    icon: ['far', 'object-ungroup'],
    iconText: 'DELIVERY',
    color: 'warning',
    title: 'Integration und Lieferung',
    description:
      'Die Software wird getestet und integriert, um die Anforderungen des Auftraggebers zu erfüllen.',
    image: illustration3,
    inverse: true
  },
  {
    icon: ['far', 'paper-plane'],
    iconText: 'SERVICE',
    color: 'warning',
    title: 'Wartung und Support',
    description:
      'Nach der Lieferung der Software ist die Kundenbetreuung und der technische Support sichergestellt.',
    image: illustration5
  }
];
