export const strings = {
  en: {
    appTitle3: 'Edit3',
    appTitle4: 'and save to reload4'
  },
  de: {
    appTitle3: 'Editiere3',
    appTitle4: 'und speichere ab um neu zu laden4'
  }
};
