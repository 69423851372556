import React from 'react';
import NavbarStandard from '../landing/NavbarStandard';
import FooterStandard from '../landing/footer-standard/FooterStandard';
import ParticlesComponent from '../landing/ParticlesComponent';
import Appointment from '../landing/appointment/Appointment';

import Processes from './Processes';

const Blog = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  });
  return (
    <>
      <NavbarStandard />
      <Processes />
      <Appointment />
      <FooterStandard />
      <ParticlesComponent id="tsparticles" />
    </>
  );
};

export default Blog;
