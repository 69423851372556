import React, { useState } from 'react';
import Hero from './hero/Hero';
import NavbarStandard from './NavbarStandard';
import Appointment from './appointment/Appointment';
import Processes from './processes/Processes';
import Services from './Services';
import Testimonial from './Testimonial';

import FooterStandard from './footer-standard/FooterStandard';
import ParticlesComponent from './ParticlesComponent';
import { AccountLanding } from '../../app/support-desk/quick-links/Admin';

import CookieNotice from '../../common/CookieNotice';
import Flex from '../../common/Flex';
import cookieImg from '../../../assets/img/icons/cookie-1.png';

import { Button, Card } from 'react-bootstrap';

const Landing = () => {
  const [show, setShow] = useState(true);
  return (
    <>
      <NavbarStandard />
      <Hero />

      <AccountLanding />
      <Processes />
      <Services />
      {/*<Cta />*/}
      <Testimonial />
      <Appointment />
      <FooterStandard />
      <ParticlesComponent id="tsparticles" />

      <CookieNotice show={show} setShow={setShow} autohide delay={5000}>
        <Card>
          <Card.Body>
            <Flex>
              <div className="pe-3">
                <img src={cookieImg} alt="cookie" width={60} />
              </div>
              <div className="">
                <p>
                  Diese Website verwendet keine Cookies .Wir glauben, dass
                  Datenschutz wichtig ist und möchten sicherstellen, dass Ihre
                  persönlichen Daten sicher aufbewahrt werden.
                </p>
                <Button
                  size="sm"
                  variant="falcon-warning"
                  className="me-3"
                  onClick={() => setShow(!show)}
                >
                  Okay
                </Button>
              </div>
            </Flex>
          </Card.Body>
        </Card>
      </CookieNotice>
    </>
  );
};

export default Landing;
