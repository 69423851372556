import Section from 'components/common/Section';
import AppContext from 'context/Context';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';

import { Typewriter } from 'react-simple-typewriter';
import SphericalWordCloud from '../SphericalWordCloud';

import { I18n } from '@aws-amplify/core';
import { FiVideo } from 'react-icons/fi';
import CreateAppointment from '../appointment/CreateAppointment';

const Hero = () => {
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);

  const [appointmentDate, setAppointmentDate] = useState();
  const {
    config: { isRTL }
  } = useContext(AppContext);

  return (
    <>
      <Section className="py-0 overflow-hidden light">
        <Row className="justify-content-center align-items-center pt-8 pt-lg-10 pb-lg-9 pb-xl-0">
          <Col
            md={11}
            lg={8}
            xl={4}
            className="pb-7 pb-xl-9 text-center text-xl-start"
          >
            <h1 className="text-white fw-light">
              Bringen Sie <br />
            </h1>
            <h1 className="text-white fw-light">
              <span className="fw-bold ">
                <Typewriter
                  words={[
                    I18n.get('Automatisierung'),
                    I18n.get('Arbeitsschutzverbesserung'),
                    I18n.get('Arbeitsschnelligkeit'),
                    I18n.get('Compliance-Erleichterung'),
                    I18n.get('Datenüberwachung'),
                    I18n.get('Datenintegrität'),
                    I18n.get('Datenqualitätsverbesserung'),
                    I18n.get('Datenverfügbarkeit'),
                    I18n.get('Datenzentralisierung'),
                    I18n.get('Effizienzsteigerung'),
                    I18n.get('Entscheidungsbeschleunigung'),
                    I18n.get('Ergebnistransparenz'),
                    I18n.get('Flexibilität'),
                    I18n.get('Gewinnmaximierung'),
                    I18n.get('Kommunikationseffizienz'),
                    I18n.get('Kooperation'),
                    I18n.get('Kostensenkung'),
                    I18n.get('Kundenbindung'),
                    I18n.get('Kundenbetreuungsverbesserung'),
                    I18n.get('Kundeninformation'),
                    I18n.get('Nachhaltigkeit'),
                    I18n.get('Personalreduktion'),
                    I18n.get('Produktqualitätssteigerung'),
                    I18n.get('Produktivität'),
                    I18n.get('Prozessautomatisierung'),
                    I18n.get('Prozessoptimierung'),
                    I18n.get('Schnelle Reaktion'),
                    I18n.get('Sicherheitssteigerung'),
                    I18n.get('Skalierbarkeit'),
                    I18n.get('Technologieentwicklung'),
                    I18n.get('Vertrauenserhöhung'),
                    I18n.get('Wettbewerbsfähigkeit'),
                    I18n.get('Wiederverwendbarkeit'),
                    I18n.get('Zeitersparnis'),
                    I18n.get('Zugänglichkeitserhöhung')
                  ]}
                  loop={false}
                  cursor={!isRTL ? true : false}
                  cursorStyle="|"
                  cursorColor="#856f25"
                  typeSpeed={40}
                  deleteSpeed={40}
                  delaySpeed={2000}
                />
              </span>
              <br />
              {I18n.get('in Ihr Unternehmen.')}
            </h1>

            <p className="lead text-white opacity-75">{I18n.get('leadText')}</p>

            {/*<Button*/}
            {/*  as={Link}*/}
            {/*  to="/dashboard/webapp"*/}
            {/*  variant="outline-light"*/}
            {/*  size="lg"*/}
            {/*  className="border-2 rounded-pill mt-4 fs-0 py-2"*/}
            {/*>*/}
            {/*  {I18n.get('Tomorrow will be too late!')}*/}
            {/*  <FontAwesomeIcon*/}
            {/*    icon="play"*/}
            {/*    transform="shrink-6 down-1 right-5"*/}
            {/*  />*/}
            {/*</Button>*/}
            <Button
              type="button"
              onClick={() => {
                setIsOpenAppointmentModal(!isOpenAppointmentModal);
              }}
              variant="outline-warning"
              size="lg"
              className="border-2 rounded-pill  py-2 fs-2"
            >
              kostenlose Strategieberatung{' '}
              <FiVideo className="text-warning fs-2" />
            </Button>
            <CreateAppointment
              isOpenAppointmentModal={isOpenAppointmentModal}
              setIsOpenAppointmentModal={setIsOpenAppointmentModal}
              appointmentDate={appointmentDate}
              setAppointmentDate={setAppointmentDate}
            />
          </Col>
          <Col
            xs={11}
            sm={11}
            md={11}
            lg={8}
            xl={8}
            className="pb-7 pb-xl-9  text-center text-xl-start"
          >
            {window.innerWidth > 720 && <SphericalWordCloud />}
          </Col>
        </Row>
      </Section>
    </>
  );
};

export default Hero;
