export default [
  { title: 'About', to: '#!' },
  { title: 'Contact', to: '#!' },
  { title: 'Terms', to: '#!' },
  { title: 'Privacy policy', to: '/privacy-policy' },
  { title: 'Impressum', to: '/imprint' },
  { title: 'Pricing Strategy', to: '#!' },
  { title: 'Technology Strategy', to: '#!' },
  { title: 'Strategy Consulting', to: '#!' },
  { title: 'FAQ', to: '/faq' }
];
