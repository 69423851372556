import illustration1 from './blog-asset/1.svg';
import illustration2 from './blog-asset/2.svg';
import illustration3 from './blog-asset/3.svg';
import illustration4 from './blog-asset/4.svg';
import illustration5 from './blog-asset/5.svg';
import illustration6 from './blog-asset/6.svg';
import illustration7 from './blog-asset/7.svg';
import illustration8 from './blog-asset/8.svg';
import illustration9 from './blog-asset/9.svg';
import illustration10 from './blog-asset/10.svg';

export default [
  {
    title: 'Erhöhung der Profitabilität',
    description:
      'Durch Maßnahmen wie Kostensenkung, Effizienzsteigerung, bessere Produktqualität und Marktbearbeitung sollte das Ziel einer erhöhten Profitabilität angestrebt werden.',
    image: illustration1
  },
  {
    color: 'warning',
    title: 'Vorteile gegenüber der Konkurrenz schaffen ',
    description:
      'Unternehmen sollten einen Wettbewerbsvorteil gegenüber anderen Unternehmen in ihrer Branche schaffen durch neue Technologien, Produktinnovation, besseren Kundenservice, effizientere Produktionsprozesse und Marktbearbeitung.',
    image: illustration2
  },
  {
    color: 'warning',
    title: 'Steigerung der Wettbewerbsfähigkeit und der Innovationskraft ',
    description:
      'Dieses Ziel zielt darauf ab, das Unternehmen in der Lage zu halten, die neuesten Entwicklungen aufzugreifen und zu nutzen, um auf dem Markt wettbewerbsfähig zu bleiben.',
    image: illustration3
  },
  {
    color: 'warning',
    title: 'Erreichen eines nachhaltigeren Wachstums ',
    description:
      'Unternehmen müssen strategische Maßnahmen ergreifen, um sicherzustellen, dass sie nachhaltiges Wachstum erzielen, ohne ihre schädlichen Auswirkungen auf die Umwelt zu vernachlässigen.',
    image: illustration4
  },
  {
    color: 'warning',
    title: 'Markenbewusstsein schaffen',
    description:
      'Eine starke Marke aufzubauen, ist wichtig, um sich gegen die Konkurrenz durchzusetzen und mehr Kunden anzuziehen.',
    image: illustration5
  },
  {
    color: 'warning',
    title: 'Verbesserung der Produkt- und Servicequalität',
    description:
      'Ein Ziel, das es Unternehmen ermöglicht, hochwertige Produkte und Services anzubieten und somit den Bedürfnissen der Kunden gerecht zu werden.',
    image: illustration6
  },
  {
    color: 'warning',
    title: 'Steigerung der Kundenzufriedenheit',
    description:
      'Unternehmen müssen auf die Bedürfnisse und Wünsche ihrer Kunden eingehen, um eine hohe Kundenzufriedenheit zu erreichen. Eine hohe Zufriedenheit der Kunden ist ein wichtiger Faktor für den unternehmerischen Erfolg.',
    image: illustration7
  },
  {
    color: 'warning',
    title: 'Kontinurliche Verbesserung',
    description:
      'Es konzentriert sich auf die kontinuierliche Verbesserung bestehender Prozesse, um die Effizienz und den Erfolg des Unternehmens zu steigern. Kontinuierliche Verbesserung bedeutet auch, technologische Innovationen zu nutzen, um Kosten und Ressourcen zu sparen sowie die Produktqualität zu verbessern.',
    image: illustration8
  },
  {
    color: 'warning',
    title: 'Erhöhung des Konfliktmanagements im Unternehmen ',
    description:
      'Konflikte in Unternehmen müssen effektiv gelöst werden, um den Erfolg zu gewährleisten. Dieses Ziel beinhaltet die Schaffung einer Kultur, in der Konflikte respektvoll und konstruktiv gelöst werden.',
    image: illustration9
  },
  {
    color: 'warning',
    title: 'Entwicklung einer positiven Unternehmenskultur ',
    description:
      'Unternehmen sollten in der Lage sein, eine positive Unternehmenskultur zu schaffen, in der Engagement und Innovation gefördert werden. Eine starke Unternehmenskultur ist ein wichtiger Erfolgsfaktor für Unternehmen.',
    image: illustration10
  }
];
