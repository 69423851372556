import React, { useState } from 'react';

import Section from 'components/common/Section';
import { Row, Button, Col } from 'react-bootstrap';

import { I18n } from '@aws-amplify/core';
import SectionHeader from '../SectionHeader';
import CreateAppointment from './CreateAppointment';
import { FiVideo } from 'react-icons/fi';

const Appointment = () => {
  const [isOpenAppointmentModal, setIsOpenAppointmentModal] = useState(false);

  const [appointmentDate, setAppointmentDate] = useState();

  return (
    <Section bg="light" className="shadow-sm">
      <SectionHeader
        title={I18n.get('kostenlose Strategieberatung')}
        subtitle="30 Minuten kostenlose Video-Konferenz für eine individuelle Strategieberatung"
      />
      <Row className="justify-content-center align-items-center">
        <Col className="text-center">
          <Button
            type="button"
            onClick={() => {
              setIsOpenAppointmentModal(!isOpenAppointmentModal);
            }}
            variant="outline-warning"
            size="lg"
            className="border-2 rounded-pill  py-2 fs-2"
          >
            Einen Termin vereinbaren <FiVideo className="text-warning fs-2" />
          </Button>
        </Col>
        <CreateAppointment
          isOpenAppointmentModal={isOpenAppointmentModal}
          setIsOpenAppointmentModal={setIsOpenAppointmentModal}
          appointmentDate={appointmentDate}
          setAppointmentDate={setAppointmentDate}
        />
      </Row>
    </Section>
  );
};

export default Appointment;
